export const hasAtLeastTwoWords = name => {
  const str = String(name).trim();

  const maxLength = 1000;
  if (str.length > 1000) {
    throw new Error(`Input string exceeds maximum length of ${maxLength}`);
  }

  return /\w+\s+\w+/.exec(str.toLowerCase());
};

export const removeSpecialCharacters = str => {
  return str.replace(/[^a-zA-Z0-9]/g, '');
};
