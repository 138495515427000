import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/lara';

export const primevue = {
  install(app) {
    app.use(PrimeVue, {
      theme: {
        preset: Aura,
        options: {
          prefix: 'bg',
          darkModeSelector: 'light',
          cssLayer: true
        }
      }
    });
  }
};
