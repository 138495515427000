import { createApp } from 'vue';
import { router } from '@/router';
import App from '@/App.vue';
import { createHead } from '@unhead/vue';
import { sentry } from '@/plugins/sentry';
import { primevue } from '@/plugins/primevue';
import { defineRules } from '@/form-rules';

import '@transfeeradev/bridge/dist/style.css';

defineRules();

const app = createApp(App);
const head = createHead();

app.use(router);
app.use(head);
app.use(sentry);
app.use(primevue);

app.mount('#app');
